<template>
	<v-container fluid style="max-width: 1500px">
		<v-fade-transition hide-on-leave>
			<template v-if="loading">
				<v-skeleton-loader key="skeleton" type="list-item-avatar" min-width="1500" height="120" class="rounded-xl" />
			</template>
			<template v-else>
				<div class="d-flex justify-space-between flex-wrap">
					<v-row key="eventFirstRow">
						<v-col cols="12" md="2" sm="3" xs="5">
							<EventLogo :loading-parent="loading" />
						</v-col>
						<v-col cols="12" md="10" sm="9" xs="7">
							<EventInfo :loading-parent="loading" />
						</v-col>
					</v-row>
					<div class="d-flex justify-space-around flex-wrap" :class="{ 'flex-column': !$vuetify.breakpoint.smAndDown }">
						<EventAttendButton :loading-parent="loading" />
						<EventAttendWebsiteButton :loading-parent="loading" />
					</div>
				</div>
			</template>
		</v-fade-transition>

		<v-row>
			<v-col cols="12" lg="6" md="12">
				<EventFeedNotices :loading-parent="loading" />
			</v-col>

			<v-col cols="12" lg="6" md="12">
				<EventDocuments v-show="event.attending" :loading-parent="loading" />
				<EventDescription :loading-parent="loading" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.title ? `${this.$t('events.event')} - ${this.title}` : this.$t('events.event'),
		}
	},
	data() {
		return {
			loading: true,
			title: '',
		}
	},
	components: {
		EventLogo: () => import('@/components/events/EventLogo.vue'),
		EventInfo: () => import('@/components/events/EventInfo.vue'),
		EventAttendButton: () => import('@/components/events/EventAttendButton.vue'),
		EventAttendWebsiteButton: () => import('@/components/events/EventAttendWebsiteButton.vue'),
		EventDescription: () => import('@/components/events/EventDescription.vue'),
		EventDocuments: () => import('@/components/events/EventDocuments.vue'),
		EventFeedNotices: () => import('@/components/events/EventFeedNotices.vue'),
	},
	watch: {
		'$route.params.id'(id) {
			this.loading = true
			this.fetchEvent(id).then(({ success }) => {
				if (success) this.title = this.event.title
				this.loading = false
			})
		},
	},
	computed: {
		...mapGetters({
			event: 'events/event',
		}),
	},
	created() {
		this.fetchEvent(this.$route.params.id).then(({ success }) => {
			if (success) this.title = this.event.title
			this.loading = false
		})
	},
	methods: {
		...mapActions('events', ['fetchEvent']),
	},
}
</script>
